<template>
    <Modal animation-name="up" class="user-modal" :is-open="modalStore.SignIn" @modal-closed="handleCloseModal">
        <template #content>
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" @click="handleCloseModal">
                            <span>×</span>
                        </button>
                        <h4 class="modal-title">{{ blok.Title }}</h4>
                    </div>

                    <div class="modal-body">
                        <Alert
                            v-if="typeof formState.responseMsg === 'string'"
                            class="mb-4"
                            :blok="{ component: 'Alert' }"
                            :message="formState.responseMsg"
                            :status="formState.success ? 'success' : 'error'"
                            :icon="formState.success ? 'check-line' : 'close-line'"
                        ></Alert>
                        <form @submit.prevent="submitForm">
                            <FormInput
                                id="email"
                                v-model="formData.email"
                                :blok="{ component: 'FormInput' }"
                                :placeholder="$t('labels.email2')"
                                :label="$t('labels.email2')"
                                name="email"
                                type="text"
                                :error-message="validationErrorMessages.email || formResponseError.email"
                                @blur="v$.email.$touch"
                            ></FormInput>
                            <FormInput
                                id="password"
                                v-model="formData.password"
                                :blok="{ component: 'FormInput' }"
                                :placeholder="$t('labels.password')"
                                :label="$t('labels.password')"
                                name="password"
                                type="password"
                                :error-message="validationErrorMessages.password || formResponseError.password"
                                @blur="v$.password.$touch"
                            ></FormInput>
                            <div class="link-to-modal-wrapper">
                                <span class="link-to-modal" @click="switchModal('ResetPassword', true)">{{
                                    blok.ForgotPasswordText
                                }}</span>
                            </div>
                            <AdvancedBtn
                                v-if="apr"
                                component-type="button"
                                component-variant="primary"
                                button-type="submit"
                                :full-width="true"
                            >
                                {{ blok.ButtonText }}
                            </AdvancedBtn>
                            <button v-else type="submit" class="sign-in-button">{{ blok.ButtonText }}</button>
                            <div v-if="!apr" class="not-registered-wrapper">
                                <span>
                                    {{ blok.NotRegisteredText }}
                                    <span class="link-to-modal" @click="switchModal('SignUp', true)">
                                        {{ blok.SignUpText }}
                                    </span>
                                </span>
                            </div>
                        </form>
                        <div class="or">
                            <div>
                                <img class="hidden md:block" :src="blok.OR.filename" :alt="blok.OR.alt" /><span
                                    class="md:hidden"
                                    >OR</span
                                >
                            </div>
                        </div>

                        <div class="bottom-wrapper">
                            <div class="bottom-section">
                                <h4 class="sign-in-with hidden md:block">{{ blok.SignInWithText }}</h4>
                            </div>
                            <div class="bottom-section">
                                <ul class="social-buttons">
                                    <li>
                                        <a
                                            v-if="blok.GoogleIcon"
                                            href="#"
                                            data-provider="google"
                                            class="google"
                                            @click.prevent.stop="authWithSocial"
                                        >
                                            <img
                                                class="hidden md:block"
                                                data-provider="google"
                                                :src="blok.GoogleIcon.filename"
                                                :alt="blok.GoogleIcon.alt"
                                                width="30"
                                            />
                                            <span class="social-text md:hidden flex" data-provider="google"
                                                ><img
                                                    data-provider="google"
                                                    :src="blok.GoogleMobileIcon.filename"
                                                    :alt="blok.GoogleMobileIcon.alt"
                                                />
                                                {{ blok.GoogleMobileText }}
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            v-if="blok.FacebookIcon"
                                            href="#"
                                            data-provider="facebook"
                                            class="facebook"
                                            @click.prevent.stop="authWithSocial"
                                        >
                                            <img
                                                class="hidden md:block"
                                                data-provider="facebook"
                                                :src="blok.FacebookIcon.filename"
                                                :alt="blok.FacebookIcon.alt"
                                                width="30"
                                            />
                                            <span class="social-text md:hidden flex" data-provider="facebook">
                                                <img
                                                    data-provider="facebook"
                                                    :src="blok.FacebookMobileIcon.filename"
                                                    :alt="blok.FacebookMobileIcon.alt"
                                                />
                                                {{ blok.FacebookMobileText }}</span
                                            >
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div v-if="apr" class="not-registered-wrapper">
                            <span>
                                {{ blok.NotRegisteredText }}
                                <span class="link-to-modal" @click="switchModal('SignUp', true)">
                                    {{ blok.SignUpText }}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import Modal from './Modal.vue';
import AdvancedBtn from '~ui/components/AdvancedBtn.vue';
import { useModalStore } from '~/../_base/store/modal';
import { email, helpers, minLength, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import type { ISignUpFormState } from '~ui/types/components/modals/SignUpModal';
import type { ISignInModalConfig, ISignInFormData } from '~ui/types/components/modals/SignInModal';

const config = useRuntimeConfig();
const { $encodeBase64, $decodeBase64 } = useNuxtApp();
const modalStore: any = useModalStore();

const authUrl = config?.public.AUTH_URL;
const returnUrl = $encodeBase64($decodeBase64(config?.public.RETURN_URL) + '/customer');

const url = `${authUrl}/callback?return_url=${returnUrl}`;
const formResponseError = ref({
    email: null,
    password: null,
});

const socialAuth = {
    mobile: false,
    redirectorDomain: authUrl,
    callbackUrlFromAuthSiteToCurrentSite: url,
};

const { t } = useI18n();
const props = defineProps<{ blok: ISignInModalConfig; formState: ISignUpFormState; apr?: boolean }>();
const emit = defineEmits<{
    (e: 'submit', formData: ISignInFormData): void;
    (e: 'reset'): void;
}>();

const formData = ref<ISignInFormData>({
    email: '',
    password: '',
    return_url: returnUrl,
});

const eraseFormData = () => {
    formData.value.email = '';
    formData.value.password = '';
    formData.value.return_url = returnUrl;

    formResponseError.value.email = null;
    formResponseError.value.password = null;
};

const rules = computed(() => {
    return {
        email: {
            required: helpers.withMessage(t('forms.emailRequired3'), required),
            email,
        },
        password: {
            required: helpers.withMessage(t('forms.password'), required),
            minLength: helpers.withMessage(t('forms.passwordMinLength2'), minLength(6)),
        },
    };
});

const v$ = useVuelidate(rules, formData);

const validationErrorMessages = computed(() => {
    return {
        email: v$.value.email.$errors.map((e) => e.$message)[0],
        password: v$.value.password.$errors.map((e) => e.$message)[0],
    };
});

const resetModal = () => {
    eraseFormData();
    v$.value.$reset();
    modalStore.toggleModal('SignIn', false);
};

const handleCloseModal = () => {
    v$.value.$reset();
    modalStore.toggleModal('SignIn', false);
};

const switchModal = (modalId: string, state: boolean) => {
    eraseFormData();
    v$.value.$reset();
    modalStore.toggleModal(modalId, state);
};

const socialLoginPopupWindow = (url: any, w: any, h: any) => {
    const left = screen.width / 2 - w / 2;
    const top = screen.height / 2 - h / 2;
    // @ts-ignore
    return window.open(
        url,
        // @ts-ignore
        config?.public.SOCIAL_LOGIN,
        'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
            w +
            ', height=' +
            h +
            ', top=' +
            top +
            ', left=' +
            left
    );
};
const authWithSocial = (e: any) => {
    e.stopPropagation();
    const provider = e.target.dataset.provider;
    const url = `${socialAuth.redirectorDomain}/${provider}?mobile=${socialAuth.mobile}&return_url=${socialAuth.callbackUrlFromAuthSiteToCurrentSite}`;

    socialLoginPopupWindow(url, 500, 600);
};

const submitForm = async () => {
    v$.value.$validate();
    if (!v$.value.$error) {
        emit('submit', formData.value);
    }
};

watch(
    () => props.formState,
    (newFormState) => {
        if (newFormState.success) {
            resetModal();
        }

        if (!newFormState.success && newFormState.responseMsg) {
            formResponseError.value.email = newFormState.responseMsg?.email?.[0];
            formResponseError.value.password = newFormState.responseMsg?.password?.[0];
        }
    },
    { deep: true }
);
</script>
<style lang="postcss">
.user-modal {
    .flex-wrapper {
        @apply p-0 md:pr-[17px];
    }

    .modal-wrapper {
        @apply md:w-auto w-full md:h-auto;

        .margin-content {
            @apply absolute md:max-w-[400px] w-full m-0 left-2/4 top-0 md:top-2/4 bottom-0 md:bottom-auto transition-none;
            transform: translate3d(-50%, 0, 0);

            @media screen(md) {
                & {
                    transform: translate3d(-50%, -50%, 0);
                }
            }

            > div {
                @apply p-0;
            }
        }

        > div {
            @apply md:border md:border-solid md:border-[rgba(0,0,0,0.2)] md:rounded-md rounded-none;
        }

        .modal-content {
            outline: 0;
            @apply md:h-auto h-[100vh] p-0 w-[100vw] md:w-auto bg-transparent;
        }
    }

    form {
        .form-input {
            .input-label:not([for='newsletter']) {
                @apply text-[#1f303d] md:hidden;
            }
        }
    }

    .modal-dialog {
        @apply md:w-[400px] w-full;
    }

    .modal-header {
        @apply md:bg-[#587a8c]  bg-white md:text-white text-black md:shadow-none shadow-[0_2px_4px_0_rgba(0,0,0,0.2)] flex justify-center items-center relative py-3.5 border-0 md:h-auto h-[6vh] z-[2];

        .close {
            @apply -translate-y-2/4 absolute text-4xl font-normal leading-[14px] cursor-pointer p-4 left-2.5 top-2/4;
        }

        .modal-title {
            @apply text-[1.2rem] text-center ml-8;
        }
    }

    .modal-body {
        @apply md:bg-white relative flex-auto p-4 pb-28 md:pb-4 h-[94vh] md:h-auto md:overflow-visible bg-[#f8f9fa] overflow-auto;
        @media (max-height: 575px) {
            & {
                @apply h-[94vh];
            }
        }
    }

    .sign-in-button,
    .social-buttons a {
        @apply rounded bg-btn border-btn-border box-border border;
        @apply flex items-center justify-center self-center w-full h-fit text-white font-normal cursor-pointer md:text-sm px-3 md:py-1.5 py-2.5 mt-2;
        transition:
            color 0.15s ease-in-out,
            background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;

        &:hover {
            @apply text-white bg-btn-hover border-btn-borderHover;
        }

        &:focus {
            @apply shadow-[0_0_0_0.2rem_rgba(249,143,67,0.5)];
        }

        .social-text {
            img {
                @apply w-[24px] h-[20px] mr-1.5;
            }
        }

        &.google {
            @apply bg-[#cf4333] border-[#cf4333];

            &:focus {
                @apply shadow-[0_0_0_0.2rem_rgba(207,67,51,0.5)] bg-[rgba(207,67,51,0.8)];
            }
        }

        &.facebook {
            @apply bg-[#475993] border-[#475993] mb-3;

            &:focus {
                @apply shadow-[0_0_0_0.2rem_rgba(71,89,147,0.5)] bg-[rgba(71,89,147,0.8)];
            }
        }

        &.google,
        &.facebook {
            @apply md:bg-transparent md:p-0 md:border-0;
        }
    }

    .link-to-modal-wrapper {
        @apply text-right;
    }

    .link-to-modal {
        @apply cursor-pointer text-[#26a9e0] text-[0.8rem] self-end;

        &:hover {
            @apply underline;
        }
    }

    .not-registered-wrapper {
        @apply text-center md:mt-2.5 border-b-[#d7d7d7] border-b border-solid md:border-none md:p-0 pb-6 md:m-0 mx-[-1rem] mt-6;

        span {
            @apply text-[0.8rem];
        }
    }

    .or {
        @apply mb-[30px] md:border-b-[#d7d7d7] md:border-b md:border-solid text-[#798489] font-semibold;

        div {
            @apply relative h-10 w-10 block mx-auto my-0 top-5;
        }
    }

    .sign-in-with {
        @apply text-[1.1rem] pl-[30px] pr-0 pt-[5px] pb-0;
    }

    .bottom-wrapper {
        @apply md:flex-row flex-col;

        .bottom-section {
            @apply w-full md:w-auto;
        }

        @apply flex justify-between w-full;

        .social-buttons {
            @apply flex md:flex-row flex-col-reverse gap-x-2.5 md:pr-[30px] pr-0 list-none w-full;
        }
    }
}
</style>
