<template>
    <div>
        <UiModalsSignInModal
            id="sbSignInModal"
            class="apr-user-modal"
            :blok="blok"
            :form-state="formState"
            :apr="true"
            @submit="submitForm"
        />
    </div>
</template>

<script lang="ts" setup>
import type { ISignUpFormState } from '@nuxt-monorepo/ui/types/components/modals/SignUpModal';
import type { ISignInModalConfig, ISignInFormData } from '@nuxt-monorepo/ui/types/components/modals/SignInModal';

const props = defineProps<{ blok: ISignInModalConfig }>();

const formState = ref<ISignUpFormState>({
    success: false,
    isSubmitted: false,
    responseMsg: null,
});

const config = useRuntimeConfig();
const { $encodeBase64, $decodeBase64 } = useNuxtApp();
const returnUrl = config?.public.RETURN_URL;
const submitForm = async (formData: ISignInFormData) => {
    formState.value.success = false;
    formState.value.isSubmitted = false;
    formState.value.responseMsg = null;

    await $publicApi('login', {
        method: 'POST',
        body: formData,
        onResponse({ response }) {
            if (response.ok) {
                formState.value.success = true;
                formState.value.isSubmitted = true;
                const redirectSplit = response._data.redirect.split('&return_url=');
                navigateTo(redirectSplit[0] + '&return_url=' + $encodeBase64($decodeBase64(returnUrl) + '/customer'), {
                    external: true,
                });
            }
        },
        onResponseError({ response }) {
            if (response) {
                if (response._data?.errors?.errors?.error?.[0]) {
                    formState.value.responseMsg = response._data.errors.errors.error[0];
                    //@ts-ignore
                } else if (
                    response._data.errors &&
                    //@ts-ignore
                    Object.values(response._data.errors)?.[0].isArray &&
                    //@ts-ignore
                    Object.values(response._data.errors)?.[0]?.[0]
                ) {
                    //@ts-ignore
                    formState.value.responseMsg = Object.values(response._data.errors)?.[0]?.[0];
                } else if (response._data.errors) {
                    //@ts-ignore
                    formState.value.responseMsg = response._data.errors;
                } else if (response._data.message) {
                    formState.value.responseMsg = response._data.message;
                } else {
                    formState.value.responseMsg = props.blok.errorMessage;
                }
                formState.value.success = false;
                formState.value.isSubmitted = true;
            }
        },
    }).catch((err: any) => console.error(err));
};
</script>

<style lang="postcss" scoped>
:deep(.user-modal) {
    .modal-wrapper {
        > .modal-content {
            @apply bg-white rounded-lg md:border md:border-solid md:border-neutral-light-grey;
            box-shadow: 0px 8px 16px 0px rgba(40, 70, 74, 0.04);
        }
    }
    .modal-header {
        @apply bg-white relative justify-start shadow-none h-auto pt-[52px] px-4 md:px-8 pb-6 md:pt-12 md:pb-0;

        .modal-title {
            @apply text-2xl leading-7 font-semibold text-text-primary-asphalt text-left ml-0;
        }

        .close {
            @apply top-8 right-5 left-auto text-text-primary-asphalt;
        }
    }
    .modal-body {
        @apply p-4 md:p-8 bg-white;
    }

    form .form-input .input-label:not([for='newsletter']) {
        @apply block;
    }

    .form-input {
        @apply py-0 mb-4;
        .input-label {
            @apply pt-0 text-xs block;
        }
        .form-element {
            @apply text-base text-text-primary-asphalt border-[#cacbcc];
            &.has-error {
                @apply border-status-error-red-100;
            }
        }
    }

    .link-to-modal-wrapper {
        @apply mb-2.5;
    }

    .link-to-modal {
        @apply text-link font-semibold text-sm;
    }

    .not-registered-wrapper {
        @apply border-none;
    }

    .social-buttons {
        @apply md:h-auto block p-0 mb-4;

        a.facebook,
        a.google {
            @apply flex justify-center items-center bg-white text-base font-semibold h-12 px-4 py-2.5 rounded-lg border border-solid border-neutral-mid-grey text-text-primary-asphalt hover:bg-neutral-light-grey transition-colors;
            > img {
                @apply mr-3 block;
            }
        }
        .social-text {
            @apply md:flex;
            img {
                @apply hidden;
            }
        }
    }

    .or {
        @apply mb-6 mt-6 border-none relative;
        img {
            @apply hidden;
        }
        div {
            @apply static h-auto w-full block text-center;
        }

        span {
            @apply text-neutral-light-grey bg-white md:inline-block relative text-sm px-2 z-10;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
            @apply bg-neutral-light-grey;
            transform: translateY(50%);
        }
    }

    .bottom-wrapper {
        @apply block;
    }

    .sign-in-with {
        @apply hidden;
    }
}
</style>
